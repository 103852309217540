<template>
  <v-app>
    <v-app-bar color="primary" elevation="0" density="compact">
      <v-toolbar color="primary" density="compact">
        <v-btn variant="tonal" class="no-uppercase ml-5"
          >Seçtiklerinizin toplamı : {{ karavanStore.tempOfferData.total }} € ,
          {{
            parseFloat(karavanStore.tempOfferData.total * karavanStore.todayRate).toFixed(2)
          }}
          ₺</v-btn
        >
      </v-toolbar>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import useKaravanStore from "@/stores/karavan_store";

const karavanStore = useKaravanStore();
</script>

<style lang="scss" scoped></style>
