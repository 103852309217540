import { createRouter ,createWebHistory } from "vue-router";
import NavBar from "../components/NavBar";
import AdminNavBar from "../components/AdminNavBar";
//import MainLayout from "../components/layouts/dxTree/MainLayout";
//import Login from "../views/auth/Login.vue";

//Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/home",
    component: NavBar,
    children: [
      {
        path: "/home",
        name: "homepage",
        component: () => import("../views/CreateOffer.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
  },

  {
    path: "/admin",
    name: "admin-main",
    redirect: "/admin/home",
    component: AdminNavBar,
    children: [
      {
        path: "/admin/home",
        name: "admin",
        component: () => import("../views/auth/AdminHome.vue"),
      },
      {
        path: "/admin/features",
        name: "features",
        component: () => import("../views/feature/FeatureList.vue"),
      },
      {
        path: "/admin/features-options/:id",
        name: "features-options",
        component: () => import("../views/option/OptionList.vue"),
      },
      {
        path: "/admin/standart-packet",
        name: "standart-packet",
        component: () => import("../views/standartPacket/StandartPacket.vue"),
      },

      {
        path: "/admin/offers",
        name: "offers",
        component: () => import("../views/offer/OfferList.vue"),
      },

      {
        path: "/admin/users",
        name: "users",
        component: () => import("../views/auth/UserList.vue"),
      },
    ],
  },
];

const router = createRouter({
 // mode: "hash",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const router = new VueRouter({
//   mode: "hash",
//   base: process.env.BASE_URL,
//   routes,
// });

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/home"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("key");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
