import { defineStore } from "pinia";
import api from "@/api/index";
import router from "@/router";

export default defineStore("auth", {
  state: () => ({
    loading: false,
    menuLoading: false,
    formData: {},
    loggedUser: {},
    userEdit : {},
    userList : [],
    showUserEditPopup : false
   
  }),

  actions: {


    async login() {
      this.loading = true;
      var store = await api.post("login", this.formData);
      this.loading = false;
      if (store) {
        console.log(store)
        this.setJWT(store);
      }

      return store;
    },

    logout() {
      localStorage.removeItem("key");
      localStorage.removeItem("user");
      localStorage.clear();
      router.push("/login");
    },

    async getUserList() {
      var res = await api.get("users");
      return res;
    },

    getLoggedUser() {
      return JSON.parse(localStorage.getItem("user"));
    },

    async createUpdateUser() {

      if (this.userEdit.id > 0) {
        let res = api.post(
          "user/update/" + this.userEdit.id,
          this.userEdit
        );
        return res;
      } else {
        let res = api.post("user/create", this.userEdit);
        return res;
      }
    },

    setJWT(store) {
      localStorage.setItem(
        "user",
        JSON.stringify(this.parseJwt(store.data.token))
      );

      localStorage.setItem("key", store.data.token);
       this.loggedUser = this.parseJwt(store.data.token);
    },

    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },

   
  },
});
