<template>
  <v-app>
    <v-app-bar color="primary" elevation="0" density="compact">
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>Yönetim Paneli</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
      prepend-icon="mdi-logout"
        variant="tonal"
        class="no-uppercase mr-5"
        @click="authStore.logout()"
        >Çıkış</v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
    expand-on-hover
        
        v-model="drawer"
      >
        <v-list>
          <v-list-item
            :prepend-avatar="avatar"
            :title="authStore.getLoggedUser().name"
            :subtitle="authStore.getLoggedUser().email"
          ></v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-star" title="Özellikler" link @click="router.push('/admin/features')" ></v-list-item>
          <v-list-item prepend-icon="mdi-mailbox" title="Gelen Teklifler" link @click="router.push('/admin/offers')"></v-list-item>
          <v-list-item prepend-icon="mdi-package" title="Standart Paket" link @click="router.push('/admin/standart-packet')"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="Kullanıcılar" link @click="router.push('/admin/users')"></v-list-item>
        </v-list>
      </v-navigation-drawer>

    <v-main>
      <v-container fluid pa-0>
        <v-row justify="center" dense>
          <v-col cols="12" lg="8" md="8" sm="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref } from "vue";
import useAuthStore from "@/stores/auth_store";
import avatar from "@/assets/logo.jpg";
let drawer = ref(false);
import router from "@/router";

const authStore = useAuthStore();
</script>

<style lang="scss" scoped></style>
