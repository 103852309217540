<template>
  
    <v-card outlined flat border>
      <v-toolbar density="compact">
        <v-toolbar-title>{{ props.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <slot name="buttons"></slot>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-divider class="mb-5"></v-divider>
        <slot name="content"></slot>
      </v-card-text>
    </v-card>
  
</template>

<script setup>
const props = defineProps(["title"]);
</script>
