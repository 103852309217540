<template>
  <v-snackbar v-model="errorStore.showMessageState" multi-line color="red">
    <v-list bg-color="red">
      <v-list-item v-for="message in errorStore.messages" :key="message">
        <v-list-item-title>
          {{ message }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:actions>
      <v-btn @click="errorStore.showMessageState = false"> Kapat </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar v-model="errorStore.infoMessageState" multi-line color="green">
    
          {{ errorStore.infoMessage }}
        
    <template v-slot:actions>
      <v-btn @click="errorStore.infoMessageState = false"> Kapat </v-btn>
    </template>
  </v-snackbar>

  <router-view />
</template>

<script setup>
import errorHadler from "@/stores/error_hander_store";

var errorStore = errorHadler();
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-uppercase {
  text-transform: unset !important;
}
</style>
