
import vuetify from './vuetify'
import pinia from '../stores'
import router from '../router'
import SimpleTextField from "@/components/SimpleTextField.vue";
import SimpleTextAreaField from "@/components/SimpleTextAreaField.vue";
import SimpleEditPopup from "@/components/SimpleEditPopup.vue";
import SimpleBoolField from "@/components/SimpleBoolField.vue";
import SimpleNumberField from "@/components/SimpleNumberField.vue";
import SimpleCard from "@/components/SimpleCard.vue";
import SimplePopup from "@/components/SimplePopup.vue";


export function registerPlugins (app) {
 
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .component('SimpleTextField', SimpleTextField)
    .component('SimpleTextAreaField', SimpleTextAreaField)
    .component('SimpleEditPopup', SimpleEditPopup)
    .component('SimpleBoolField', SimpleBoolField)
    .component('SimpleCard', SimpleCard)
    .component('SimplePopup', SimplePopup)
    .component('SimpleNumberField', SimpleNumberField)
   

    
}