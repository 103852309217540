import { defineStore } from "pinia";

export default defineStore("errorHadler", {
  state: () => ({
    formErrors:{},
    messages:[],
    infoMessage : "",
    infoMessageState : false,
    showMessageState : false,
    showFormErorros:false,
  }),
  getters: {

  },
  actions: {
    showMessage(messag){
        this.messages=messag;
        this.showMessageState=true;
    },
    hideMessage(){
        this.showMessage=false;
    },
    prepareApiError(datas){  
        
        if(Array.isArray(datas.messages)){
            for (let index = 0; index < datas.messages.length; index++) {
                const message = datas.messages[index];
                this.formErrors[message.fieldName] = message.errorMessage;
            }
            
           
            
        }else{
            this.showMessage(datas.messages);
        }
    },

    getFormErrors(key){
      if(this.formErrors[key]){
        return this.formErrors[key]
      }else{
        return []
      }
    },

    anyErrors(key){
      if(this.formErrors[key]){
        return this.formErrors[key].length > 0
      }
      return false;
      
    }
  },
});
