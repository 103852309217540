<template>
  <v-text-field
    v-bind="attrs"
    density="compact"
    dense
    variant="outlined"
    :error="errors.anyErrors(props.errorField)"
    :error-messages="errors.getFormErrors(props.errorField)"
    type="number"
    color="blue"
  >
  </v-text-field>
</template>

<script setup>
import { useAttrs } from "vue";
import useErrorHandler from "@/stores/error_hander_store";
const props = defineProps(["errorField"]);
const attrs = useAttrs();
const errors = useErrorHandler();
</script>

<style scoped>
:deep(.v-input--is-focused .v-input__slot) {
  border: 2px solid #005fcc !important;
  border-bottom-color: rgba(168, 14, 14, 0.38) !important;
}
</style>
