import axios from "axios";
import errorHadler from "@/stores/error_hander_store";

const apiClient = axios.create({
  //baseURL: "http://localhost:8080/",
  baseURL: "https://api.nefeskaravan.com/",
});

apiClient.CancelToken = axios.CancelToken;
apiClient.isCancel = axios.isCancel;

apiClient.interceptors.request.use(
  (config) => {

    config.headers["Content-Type"] = "multipart/form-data";
    //config.headers["Content-type"] = `application/json; charset=utf-8`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    if (response.status === 200) errorHadler().formErrors = {};

    return response;
  },
  (error) => {
    console.log(error);

    if (error.response) {
      errorHadler().prepareApiError(error.response.data);
      if (error.response.status === 401) {
        console.log(error);
        errorHadler().showMessage([error.response.data.message]);
      } else if (error.response.status === 404)
        errorHadler().showMessage([error.response.datamessage]);
    } else {
      errorHadler().showMessage([error.message]);
    }
  }
);

export default apiClient;
