import { defineStore } from "pinia";
import api from "@/api/index";

export default defineStore("karavan", {
  state: () => ({
    loading: false,
    featureEditPopupVisible: false,
    totalPrice: 0,
    showStdFeature: false,
    showSendOfferPopup: false,
    tempOfferData: {},
    featureFormData: {},
    todayRate: 0,

    showOfferDetail: false,

    /* opsiyonlar */
    optionFormData: {},
    optionPostData: new FormData(),
    optionEditPopupVisible: false,

    /* standart packet */
    stdPacketEditPopupVisible: false,
    stdPacketData: {},
    stdPacketDataList: [],
    standartPrice: 0,
  }),

  actions: {
    async getFeaturesWithOption() {
      return await api.get("features/getWithOptions");
    },

    async getFeatures() {
      return await api.get("features");
    },

    async deleteFeature(id) {
      return await api.post("features/delete/" + id);
    },

    async createUpdateFeature() {
      if (this.featureFormData.id > 0) {
        let res = api.post(
          "features/update/" + this.featureFormData.id,
          this.featureFormData
        );
        return res;
      } else {
        let res = api.post("features/create", this.featureFormData);
        return res;
      }
    },

    async getTodayRate() {
      await api.get("getTodayRate").then((res) => {
        console.log(res);
        this.todayRate = parseFloat(res.data.data[0]);
      });
    },


    /* OPSİYONLAR */

    async getOptions(id) {
      return await api.get("options/getbyfeatureid/" + id);
    },

    async createUpdateOption() {
      if (this.optionFormData.id > 0) {
        let res = await api.post(
          "options/update/" + this.optionFormData.id,
          this.optionPostData
        );
        return res;
      } else {
        let res = await api.post("options/create", this.optionPostData);
        return res;
      }
    },

    async deleteOption(id) {
      return await api.post("options/delete/" + id);
    },

    /* TEKLİFLER */
    async createOffer() {
      let res = await api.post("offer/create", this.tempOfferData);
      return res;
    },

    async getOffers() {
      let res = await api.get("offer");
      return res;
    },

    async getOfferDetails(id) {
      let res = await api.get("offer/details/" + id).then((res) => {
        this.offerDetailList = res.data.data;

        this.showOfferDetail = true;
      });
      return res;
    },

    /* STANDART ÖZELLİKLER */

    async getStandartPrice() {
      return await api.get("standart-features/standartprice");
    },

    async getStandartPacketList() {
      await api.get("standart-features").then((res) => {
        if (res) this.stdPacketDataList = res.data.data;
      });
    },

    async createUpdateStdPacket() {
      if (this.stdPacketData.id > 0) {
        let res = api.post(
          "standart-features/update/" + this.stdPacketData.id,
          this.stdPacketData
        );
        return res;
      } else {
        let res = api.post("standart-features/create", this.stdPacketData);
        return res;
      }
    },

    async deleteStdPacketItem(id) {
      return await api.post("standart-features/delete/" + id);
    },

    formatDate(date) {
      if (!date) return null;
      const [day, month, year] = date
        .toLocaleString()
        .substring(0, 10)
        .split(".");
      return `${year}-${month}-${day}`;
    },

   
  },
});
